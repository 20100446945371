import React from 'react'
import {PageProps} from 'gatsby'
import ServiceContent from '../../components/ServiceContent'
import {servicesBreadcrumbElement} from '.'
import {LayoutProps} from '../../components/Layout.context'
import {Helmet} from 'react-helmet'
import RecoListing from '../../components/RecoListing'
import useRecoListingQuery from '../../hooks/useRecoListingQuery'
import styled from 'styled-components'
import {StaticImage} from 'gatsby-plugin-image'
import {recLink} from '../../data/menu'
import GarantiesReco from "../smartphone/GarantiesReco";

const PaddingContainer = styled.div`
    padding-bottom: 40px;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        padding-bottom: 50px;
    }
`
const LeftDiv = styled.div`
    display: flex;
    align-items: center;

    & > div:first-child {
        font-size: 96px;
        font-weight: bold;
        line-height: normal;
        border-right: solid;
        padding-right: 15px;
        margin-right: 30px;
        @media (min-width: ${({theme}) => theme.breakpoints.md}) {
            padding-right: 30px;
        }
    }
`

const SeparatorDiv = styled.div`
    border-bottom: 3px solid ${({theme}) => theme.colors.secondary};
    width: 130px;
    height: 0;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        border-right: 3px solid ${({theme}) => theme.colors.secondary};
        border-bottom: none;
        height: 130px;
        width: 0;
    }
`
const SmartphonesReconditionnesPage: React.FC<PageProps> = () => {
    const items = useRecoListingQuery()

    return (
        <ServiceContent.ServiceContainer>
            <Helmet>
                <meta
                    name="description"
                    content="Achetez un reconditionné est le premier geste que vous pouvez faire pour la planète, si c'est un Save en plus, ca compte double !"
                />
            </Helmet>

            <ServiceContent.PageTitle>
                Les smartphones reconditionnés{' '}
                <ServiceContent.PageTitleWithColor>
                    SAVE
                </ServiceContent.PageTitleWithColor>
            </ServiceContent.PageTitle>

            <PaddingContainer>
                <ServiceContent>
                    <PaddingContainer>
                        <ServiceContent.Paragraph>
                            La lutte contre la pollution vous tient à coeur ? Vous y êtes allé vraiment trop
                            fort sur votre téléphone et sa réparation n’est pas possible cette
                            fois-ci ? Optez pour un des nombreux reconditionnés que nous vendons dans nos boutiques SAVE
                            !
                            Vous ferez un petit geste pour la planète et un grand geste pour votre porte-monnaie.
                        </ServiceContent.Paragraph>
                    </PaddingContainer>

                    <PaddingContainer>
                        <GarantiesReco />
                    </PaddingContainer>

                    <PaddingContainer>
                        <ServiceContent.Button to={recLink}>
                            Je veux mon reconditionné{' '}
                            <StaticImage
                                src="../../images/Phone.svg"
                                alt="Reconditionné"
                                placeholder="blurred"
                                quality={100}
                                width={32}
                                height={31}
                            />
                        </ServiceContent.Button>
                    </PaddingContainer>

                    {items && items.length > 0 && (
                        <PaddingContainer>
                            <RecoListing
                                items={items}
                                titleChild={
                                    <ServiceContent.Title>
                                        <ServiceContent.PageTitleWithColor>
                                            Reconditionnés
                                        </ServiceContent.PageTitleWithColor>
                                        , découvrez les smartphones que nous sauvons pour vous
                                    </ServiceContent.Title>
                                }
                            />
                        </PaddingContainer>
                    )}

                    <PaddingContainer>
                        <ServiceContent.Title>
                            <ServiceContent.PageTitleWithColor>
                                Reconditionnés{' '}
                            </ServiceContent.PageTitleWithColor>{' '}
                            Save, comment ça marche ?{' '}
                        </ServiceContent.Title>

                        <LeftDiv>
                            <ServiceContent.Paragraph>?</ServiceContent.Paragraph>
                            <ServiceContent.Paragraph>
                                Découvrez nos smartphones reconditionnés, soigneusement inspectés,
                                testés et réparés si nécessaire pour garantir une performance optimale.
                                Nous remplaçons les composants défectueux par des pièces neuves et réinitialisons
                                les logiciels, tout en effaçant les anciennes données grâce à notre logiciel certifié
                                RGPD. Ainsi, votre compagnon de route est prêt à vous accompagner durablement.
                                Profitez également d'une boîte complète d'accessoires essentiels et d'une
                                garantie de deux ans sur votre produit.
                            </ServiceContent.Paragraph>
                        </LeftDiv>
                    </PaddingContainer>

                    <PaddingContainer>
                        <ServiceContent.Title>
                            <ServiceContent.PageTitleWithColor>
                                Reconditionné{' '}
                            </ServiceContent.PageTitleWithColor>{' '}
                            ou neuf ?{' '}
                        </ServiceContent.Title>

                        <ServiceContent.AttributesDiv isCenter>
                            <ServiceContent.ComparativeCard
                                isWhite={false}
                                title="Reconditionné"
                            >
                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Prix"
                                />
                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Impact environnemental"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Garantie de 24 mois"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Testé et contrôlé"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Accessoires fournis"
                                />
                            </ServiceContent.ComparativeCard>
                            <SeparatorDiv></SeparatorDiv>
                            <ServiceContent.ComparativeCard isWhite={true} title="Neuf">
                                <ServiceContent.ComparativeContent
                                    starNumber={1}
                                    title="Prix"
                                />
                                <ServiceContent.ComparativeContent
                                    starNumber={1}
                                    title="Impact environnemental"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Garantie de 24 mois"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Testé et contrôlé"
                                />

                                <ServiceContent.ComparativeContent
                                    starNumber={3}
                                    title="Accessoires fournis"
                                />
                            </ServiceContent.ComparativeCard>
                        </ServiceContent.AttributesDiv>
                    </PaddingContainer>

                    <PaddingContainer>
                        <ServiceContent.Title>
                            <ServiceContent.PageTitleWithColor>
                                Reconditionné
                            </ServiceContent.PageTitleWithColor>
                            , vous souhaitez l’acheter en boutique ?
                        </ServiceContent.Title>

                        <ServiceContent.Paragraph>
                            Vous préférez voir et toucher votre téléphone reconditionné avant
                            de l’acheter ? Aucun problème, vous avez plus de 150 magasins en
                            France qui vendent des téléphones reconditionnés Save.
                        </ServiceContent.Paragraph>
                    </PaddingContainer>
                    <PaddingContainer>
                        <ServiceContent.Button to={recLink}>
                            Trouver une boutique{' '}
                            <StaticImage
                                src="../../images/localisation.svg"
                                alt="Localisation"
                                placeholder="blurred"
                                quality={100}
                                width={32}
                                height={31}
                            />
                        </ServiceContent.Button>
                    </PaddingContainer>
                </ServiceContent>
            </PaddingContainer>
        </ServiceContent.ServiceContainer>
    )
}

const layoutProps: LayoutProps = {
    title: 'Oui, le leader de la réparation européen reconditionne également',
    breadcrumb: [
        servicesBreadcrumbElement,
        {label: 'Smartphones reconditionnés'},
    ],
}

export default Object.assign(SmartphonesReconditionnesPage, {
    layoutProps,
})
