import React from 'react'
import { IRecoListing } from '../hooks/useRecoListingQuery'
import CardList from './CardList'

interface RecoListingProps {
  items: IRecoListing[]
  titleChild: React.ReactNode
}

const RecoListing: React.FC<RecoListingProps> = ({ items, titleChild }) => (
  <CardList>
    <CardList.CardListHeader titleDescription="" titleDesktop={titleChild} />
    <CardList.CardListGrid>
      {items.map((item, index) => {
        if (item.listing_link != undefined && item.listing_link != '') {
          return (
            <CardList.CardListCard
              key={index}
              index={index}
              alt="Nos produits reconditionnés"
              brand={item.brand ?? ''}
              imageUrl={item.imageURL ?? ''}
              link={item.listing_link ?? ''}
              model={item.model ?? ''}
              price={item.price ?? 0}
              label={item.additional_data ?? ''}
            />
          )
        }
      })}
      {/* //retrouvez tous nos appareils */}
    </CardList.CardListGrid>
  </CardList>
)

export default RecoListing
