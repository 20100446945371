import {useStaticQuery, graphql} from 'gatsby'
import {RecoListingQuery} from '../_generated/codegen/graphqlTypes'
import {Unpacked} from '../utils/Unpacked'
import notEmpty from '../utils/notEmpty'

export type IRecoListing = NonNullable<
    Unpacked<RecoListingQuery['strapi']['recoListings']>
>

const useRecoListingQuery = (): IRecoListing[] => {
    const {strapi}: RecoListingQuery = useStaticQuery(
        graphql`
      query RecoListing {
        strapi {
          recoListings(sort: "order", limit: 10) {
            model
            brand
            price
            imageURL
            additional_data
            order
            listing_link
          }
        }
      }
    `
    )
    return strapi.recoListings?.filter(notEmpty) ?? []
}

export default useRecoListingQuery
